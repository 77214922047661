<template>
  <v-card
    :loading="loading"
    :disabled="loading"
    v-if="order"
    :tile="tile"
    :outlined="!tile"
    >
    <v-card-title
      class="pb-0"
      >
      {{ checkout ? 'Confirmación' : ('Orden ' + order.number) }}
      <v-spacer></v-spacer>
      <v-btn
        v-if="close"
        icon
        @click="$emit('close')"
        >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      >
      <v-list
        dense
        >
        <v-subheader
          >
          Productos
        </v-subheader>
        <div
          v-if="order.children.length"
          >
        </div>

        <div
          v-else
          >
          <v-list-item
            v-for="(lineItem) in order.lineItems"
            :key="lineItem.id"
            class="px-2"
            >
            <v-list-item-content
              >
              <v-list-item-title
                class="text-right"
                >{{ lineItem.variant.name }}</v-list-item-title>
              <v-list-item-subtitle>Cantidad: {{ lineItem.quantity }} - Precio: {{ lineItem.displayAmount }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <template
                  v-if="lineItem.variant"
                  v-for="(option, idx) in lineItem.variant.variantOptionValues"
                  >
                  <span
                    :key="option.id"
                    class="caption"
                    >
                    {{ option.optionValue.optionType.name }}: {{ option.optionValue.value }} {{ (idx + 1) != lineItem.variant.variantOptionValues.length ? '|' : '' }}
                  </span>
                </template>
              </v-list-item-subtitle>
              <v-list-item-subtitle>Cod.: {{ lineItem.variant.sku }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="px-2"
            >
            <v-list-item-content>
              <v-list-item-title>Descuentos</v-list-item-title>
              <v-list-item-subtitle>{{ order.displayPromotionsAmount }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <div
          v-if="order.children"
          v-for="child in order.children"
          :key="child.id"
          >
          <div
            @click.stop="$router.push({name: 'profile', params: {slug: child.store.slug}})"
            style="cursor: pointer"
            class="primary--text font-weight-bold d-flex justify-space-between align-center"
            >
            <div>
              {{ child.store.name }}
            </div>

            <div
              class="caption font-weight-medium"
              >
              VER PERFIL
            </div>
          </div>
          <v-divider></v-divider>
          <template 
            v-for="(lineItem) in child.lineItems"
            >
            <v-list-item
              :key="lineItem.id"
              class="px-2"
              v-if="lineItem"
              >
              <v-list-item-content
                class="body-1"
                >
                <v-list-item-title>{{ lineItem.variant.name }}</v-list-item-title>
                <v-list-item-subtitle>Cantidad: {{ lineItem.quantity }} - <span class="text--primary">Precio: {{ lineItem.displayAmount }}</span></v-list-item-subtitle>
                <v-list-item-subtitle>Cod.: {{ lineItem.variant.sku }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            class="px-2"
            >
            <v-list-item-content
              class="text-right"
              >
              <v-list-item-title>Descuentos</v-list-item-title>
              <v-list-item-subtitle>{{ child.displayPromotionsAmount }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-list-item
          class="px-2"
          >
          <v-list-item-content
            class="text-right"
            >
            <v-list-item-title
              class="font-weight-regular"
              >
              Subtotal
            </v-list-item-title>

            <v-list-item-subtitle
              class="body-1 text--primary"
              >
              {{ order.displayDiffAmount }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        dense
        v-if="order.address"
        >
        <v-subheader
          >
          Datos de envío
        </v-subheader>
        <template 
          v-for="(value, field) in address"
          v-if="!['lat', 'lng', 'zipcode', 'fullAddress'].includes(field)"
          >
          <v-list-item
            :key="field"
            class="px-2"
            v-if="value"
            >
            <v-list-item-content>
              <v-list-item-title
                class="d-flex flex-row justify-space-between"
                >
                <div
                  class="font-weight-regular shrink"
                  >
                  {{ $t('address.' + field) }}
                </div>

                <div
                  class="grow text-right"
                  >
                  {{ value }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <v-divider></v-divider>

      <v-list
        dense
        >
        <v-subheader
          >
          Métodos de envío
        </v-subheader>

        <template
          v-if="order.children.length"
          v-for="child in order.children"
          >
          <shipment
            v-if="child.shipments[0]"
            :shipment="child.shipments[0]"
            :order="child"
            ></shipment>
        </template>

        <shipment
          v-if="!order.children.length && order.shipments[0]"
          :shipment="order.shipments[0]"
          :order="order"
          ></shipment>
      </v-list>

      <template
        v-if="((order.children[0] || {}).payments || []).length"
        >
        <v-divider></v-divider>

        <div
          >
          <v-subheader
            class="caption px-2"
            >
            Forma de pago
          </v-subheader>

          <template
            v-for="child in order.children"
            v-if="child.payments[0]"
            >
            <v-subheader
              class="caption px-2 font-weight-bold secondary--text d-flex justify-space-between"
              >
              <div>
                <v-icon class="mt-n1" color="secondary" small>mdi-storefront</v-icon> {{ child.store.name }}
              </div>

              <div>
                Payku - WebPay
              </div>

              <div>
                <v-chip
                  x-small
                  :color="child.payments[0].state == 'pending' ? '' : 'success'"
                  >
                  {{ child.payments[0].state == 'pending' ? 'Pendiente' : 'Completado' }}
                </v-chip>
              </div>
            </v-subheader>
            <div
              class="caption px-2 pb-4 black--text"
              >
              <div
                class="d-flex justify-space-between"
                >
                <div>
                  <div
                    class="d-flex justify-space-between align-center"
                    >
                    <span class="font-weight-bold mr-1">Total:</span> {{ child.payments[0].displayTotalAmount }}
                  </div>
                  <template
                    v-if="false"
                    >
                    <div
                      class="d-flex justify-space-between align-center"
                      >
                      <span class="font-weight-bold mr-1">Pagado el:</span> {{ (new Date(child.payments[0].completedAt)).toLocaleDateString() }}
                    </div>
                    <div
                      class="d-flex justify-space-between align-center"
                      >
                      <span class="font-weight-bold mr-1">Tarjeta terminada en:</span> {{ child.payments[0].lastDigits }}
                    </div>
                  </template>
                </div>
                <div
                  v-if="false"
                  >
                  <div
                    class="d-flex justify-space-between align-center"
                    >
                    <span class="font-weight-bold mr-1">Cód. de autorización:</span> {{ child.payments[0].authorizationCode }} 
                  </div>
                  <template v-if="child.payments[0].installments > 0"> 
                    <div
                      class="d-flex justify-space-between align-center"
                      >
                      <span class="font-weight-bold mr-1">Método de pago:</span> Crédito
                    </div>
                    <div
                      class="d-flex justify-space-between align-center"
                      >
                      <span class="font-weight-bold mr-1">Cuotas:</span> {{ child.payments[0].installments }}
                    </div>
                    <div
                      class="d-flex justify-space-between align-center"
                      >
                      <span class="font-weight-bold mr-1">Valor cuota:</span> {{ child.payments[0].installmentsAmount }}
                    </div>
                  </template>
                  <template v-else> 
                    <div
                      class="d-flex justify-space-between align-center"
                      >
                      <span class="font-weight-bold mr-1"> Método de pago:</span> Débito
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>

      <v-divider></v-divider>

      <v-list
        dense
        >
        <v-list-item
          class="px-2 text-right"
          >
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-regular text--primary body-1 mb-2"
              >
              Total
            </v-list-item-title>

            <v-list-item-subtitle
              class="font-weight-bold text-h6 text--primary"
              >
              {{ order.displayTotalAmount }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { Order } from '@/graphql/queries/orders'

const Shipment = () => import('./Shipment')

export default {
  data () {
    return {
      order: null,
    }
  },

  props: {
    currentOrder: {
      type: Object,
      required: true
    },

    close: {
      type: Boolean,
      required: false,
      default: false
    },

    checkout: {
      type: Boolean,
      required: false,
      default: true
    },

    tile: {
      type: Boolean,
      required: false,
      default: true
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mounted () {
    this.fetchOrder()
  },

  methods: {
    fetchOrder () {
      this.$apollo.query({
        query: Order,
        variables: {
          orderToken: this.currentOrder.orderToken
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.order = res.data.order
      })
    },
  },

  computed: {
    address () {
      if (this.order) {
        let address = this.order.address
        delete address.id
        delete address.__typename
        return address
      }
    }
  },

  components: { Shipment }
}
</script>
